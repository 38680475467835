<template>
    <el-header style="text-align: right; font-size: 12px;">
      <span style="margin-right: 15px">到期时间：{{ userinfo.timeout }}</span>
      <span>{{ userinfo.username }}</span>
      <el-dropdown>
        <i class="el-icon-setting" style="margin-left: 15px"></i>
        <template #dropdown>
          <el-dropdown-menu>
              <el-dropdown-item @click="signoutBtn">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-header>
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>复制商品</el-breadcrumb-item>
        <el-breadcrumb-item>单品复制</el-breadcrumb-item>
      </el-breadcrumb> -->
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { userByid, signout } from 'network/user'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Breadcrumb',
  setup () {
    const state = reactive({
      userinfo: []
    })
    // 退出
    const signoutBtn = () => {
      // 退出
      signout().then(res => {
        console.log(res)
        if (res.status === 1) {
          ElMessage.success({
            message: '恭喜你，退出成功！',
            type: 'success'
          })
        } else {
          ElMessage.error('退出失败')
        }
      })
    }
    onMounted(() => {
      // 运费模版
      userByid().then(res => {
        console.log(res)
        if (res.status === 1) {
          state.userinfo = res.result
        } else {
          state.userinfo = []
        }
      })
    })
    return {
      ...toRefs(state),
      signoutBtn
    }
  }
})
</script>

<style lang="scss" scoped>
  .con-right{
    margin-left: 200px!important;
  }
  .el-header {
    background-color: #f2f2f2;
    color: #333;
    line-height: 56px;
  }
</style>
