import { request } from './request'

/*
* 获取授权用户的信息
*/
export function userByid () {
  return request({
    url: '/api/alibaba/user/userByid'
  })
}

/*
* 获取授权用户退出
*/
export function signout () {
  return request({
    url: '/api/alibaba/user/signout'
  })
}
