<template>
    <el-menu
      :uniqueOpened="true"
      :default-active="navActive"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      style="border:0">
      <el-menu-item index="1" @click="goTo('/')">
        <i class="el-icon-document"></i>
        <template #title>首页</template>
      </el-menu-item>
      <!-- <el-submenu index="2">
        <template #title>
          <i class="el-icon-setting"></i>
          <span>复制管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="2-1" style="margin-top: -14px;" @click="goTo('/fuzhi')">
            <span class="el-icon-dot1"></span>阿里复制
          </el-menu-item>
          <el-menu-item index="2-2" @click="goTo('/fuzhi/list')">
            <span class="el-icon-dot1"></span>复制记录
          </el-menu-item>
          <el-menu-item index="2-3" @click="goTo('/fuzhi/help')">
            <span class="el-icon-dot1"></span>帮助中心
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->
      <el-submenu index="3">
        <template #title>
          <i class="el-icon-location"></i>
          <span>自动重发</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="3-1" style="margin-top: -14px;" @click="goTo('/chongfa')">
            <span class="el-icon-dot1"></span>重发设置
          </el-menu-item>
          <el-menu-item index="3-2" @click="goTo('/chongfa/task')">
            <span class="el-icon-dot1"></span>重发任务
          </el-menu-item>
          <el-menu-item index="3-3" @click="goTo('/chongfa/help')">
            <span class="el-icon-dot1"></span>帮助中心
          </el-menu-item>
          <!-- <el-menu-item index="3-3" @click="goTo('/chongfa/list')">
            <span class="el-icon-dot1"></span>重发记录
          </el-menu-item> -->
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4">
        <template #title>
          <i class="el-icon-location"></i>
          <span>批量修改</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="4-1" style="margin-top: -14px;" @click="goTo('/piliang')">
            <span class="el-icon-dot1"></span>批量标题
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5">
        <template #title>
          <i class="el-icon-location"></i>
          <span>商品管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="5-1" style="margin-top: -14px;" @click="goTo('/shangpinguanli')">
            <span class="el-icon-dot1"></span>批量下架
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

    </el-menu>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
// import { AlarmClock, Bell, SoldOut } from '@element-plus/icons'

export default defineComponent({
  name: 'Header',
  props: ['navActive'],
  components: {
    // AlarmClock,
    // Bell,
    // SoldOut
  },
  setup () {
    const router = useRouter()
    const handleOpen = (key, keyPath) => {
      console.log(key, keyPath)
    }
    const handleClose = (key, keyPath) => {
      console.log(key, keyPath)
    }
    // 类目跳转
    const goTo = (url) => {
      router.push({ path: url })
    }
    return {
      goTo,
      handleOpen,
      handleClose
    }
  }
})
</script>
<style lang="scss" scoped>
  // .el-icon{
  //   width: 28px;
  //   height: 28px;
  //   line-height: 28px;
  //   font-size: 14px;
  //   background-color: #61b2fc;
  //   border-radius: 50%;
  //   text-align: center;
  //   color: #fff!important;
  //   margin-right: 10px;
  // }
  .el-icon-dot1{
    width: 8px!important;
    height: 8px!important;
    line-height: 8px!important;
    overflow: hidden;
    border-radius: 50%;
    background-color: #ffffff;
    margin-right: 10px;
  }
</style>
